import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { addDoc, arrayRemove, arrayUnion, collection, document, getDoc, getDocs, getFirestore, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";


const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const App = initializeApp(firebaseConfig);

// // Initialize the Vertex AI service
// const vertexAI = getVertexAI(App);
// // Initialize the generative model with a model that supports your use case
// const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(App);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(App);

export { addDoc, arrayRemove, arrayUnion, auth, collection, db, document, getDoc, getDocs, onSnapshot, serverTimestamp, setDoc, updateDoc };

