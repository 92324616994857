<template>
  <HeaderBar></HeaderBar>
  <div class="container">
    <div class="card my-5">
      <div class="card-body">
        <div class="container">
          <h1>Company Details </h1>

          <div v-if="company">
            <form @submit.prevent="updateCompany">
              <div class="mb-3">
                <label for="companyName" class="form-label">Company Name</label>
                <input type="text" id="companyName" class="form-control" v-model="company.companyName" required />
              </div>

              <div class="mb-3">
                <label for="companyLegal" class="form-label">Company Legal Name</label>
                <input type="text" id="companyLegal" class="form-control" v-model="company.companyLegal" required />
              </div>

              <div class="mb-3">
                <label for="companyDomain" class="form-label">Company Domain</label>
                <input type="text" id="companyDomain" class="form-control" v-model="company.companyDomain" required />
              </div>

              <div class="mb-3">
                <label for="companyAbstract" class="form-label">Company Abstract </label> 
                <button type="button" class="mx-2 btn btn-primary btn-sm">Generate</button>
                <textarea class="form-control" id="companyAbstract" rows="3" v-model="company.companyAbstract" required></textarea>
              </div>

              <div class="mb-3">
                <label for="companyAvatar" class="form-label">Company Avatar</label>
                <input type="text" id="companyAvatar" class="form-control" v-model="company.companyAvatar"  />
              </div>

              <div class="mb-3">
                <label for="companyLocation" class="form-label">Company Location</label>
                <div class="mb-3 row">
                  <div class="col">
                    <input type="text" id="companyCity" class="col-sm-2 form-control" v-model="company.companyCity"  />
                  </div>
                  <div class="col">
                    <input type="text" id="companyCountry" class="col-sm-2 form-control" v-model="company.companyCountry"  />
                  </div>
                </div>
              </div>

              <div class="mb-3 form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="company.showRecord" id="showRecord">
                <label class="form-check-label" for="showRecord">Show Record</label>
              </div>

              <button type="submit" class="btn btn-primary">Update Company</button>
            </form>
          </div>
          <div v-else>
            <p>Loading company details...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import HeaderBar from '../components/HeaderBar';
import { db, doc, getDoc, updateDoc } from '../firebase';

export default {
  name: 'CompanyDetails',
  components: {
    HeaderBar,
  },
  setup() {
    const route = useRoute();
    const company = ref(null);
    const companyDomain = route.params.companyDomain;

    const fetchCompany = async () => {
      try {
        const docRef = doc(db, 'companies', companyDomain);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          company.value = docSnap.data();
        } else {
          console.error('No such company document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    const updateCompany = async () => {
      try {
        const docRef = doc(db, 'companies', companyDomain);
        await updateDoc(docRef, { ...company.value });
        alert('Company details updated successfully');
      } catch (error) {
        console.error('Error updating document:', error);
      }
    };

    onMounted(async () => {
      await fetchCompany();
    });

    return {
      company,
      updateCompany,
    };
  },
};
</script>

<style scoped>
.myFloat_Right {
  float: right;
}
</style>