<template>
  <HeaderBar></HeaderBar>
  <div class="container">
    <div class="card my-5">
      <div class="card-body">
        <div class="container">
          <h1>Foreshadow Administration</h1>

          <div v-if="alert" class="alert alert-warning alert-dismissible fade show" role="alert">
            {{ alert }}
            <button type="button" class="btn-close" @click="closeAlert"></button>
          </div>

          <form @submit.prevent="handleSubmit">
  <div class="row mt-4">
    <div class="col-xs-10 col-10">
      <input
        type="text"
        class="form-control"
        v-model="domains"
        required
        placeholder="Enter company domain, or domains (comma separated)"
        @input="validateDomains"
      />
      <div v-if="domainError" class="text-danger">
        {{ domainError }}
      </div>
    </div>
    <div class="col-xs-2 col-2">
      <button type="submit" class="btn btn-warning w-100" :disabled="!isValidDomains">
        Add Companies
      </button>
    </div>
  </div>
</form>
          
        </div>
        <div class="mt-3">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'companies' }" @click="activeTab = 'companies'">Companies</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'users' }" @click="activeTab = 'users'">Users</a>
            </li>
          </ul>
        </div>
        <div v-if="activeTab === 'companies'">
          <table class="table mt-3">
            <thead>
              <tr>
                <th scope="col" class="col"></th>
                <th scope="col" class="col-3">Company</th>
                <th scope="col" >Status</th>
                <th scope="col" class="col-2">Last updated</th>
                <th scope="col" class="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(company, index) in companies" :key="index">
                <td scope="row" class="align-middle">
                  <i class="text-success bi bi-check-circle-fill" v-if="company.companyStatusCode === 'success'"></i>
                  <i class="text-danger bi bi-x-circle" v-else-if="company.showRecord === false"></i>
                  <i class="text-danger bi bi-x-circle-fill" v-else></i>
                </td>
                <td v-if="company.companyName" class="align-middle">{{ company.companyName }}</td>
                <td v-else class="align-middle">{{ company.companyDomain }}</td>
                <td class="align-middle">{{ company.companyStatusText }}</td>
                <td class="align-middle"><p v-if="formatDate(company.lastUpdated)">{{ formatDate(company.lastUpdated) }}</p></td>
                
                <td class="float-right">
                  <div class="btn-group">
                    <button class="btn btn-link nav-link py-2 px-0 px-lg-2 d-flex align-items-center" type="button" aria-expanded="false" data-bs-toggle="dropdown">
                      <i class="bi bi-three-dots-vertical"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li class="nav-item">
                        <!-- Pass the company ID or domain to the runNewTest method -->
                        <RouterLink class="dropdown-item" aria-current="page" to="#" @click="runNewTest(company)">Run new test</RouterLink>
                      </li>
                      <li class="nav-item">
                        <RouterLink  class="dropdown-item" :to="{ name: 'CompanyAdmin', params: { companyDomain: company.companyDomain } }">Edit Company</RouterLink>
                      </li>
                      
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="activeTab === 'users'" class="mt-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Date Joined</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="index">
                <td class="align-middle">{{ user.name }}</td>
                <td class="align-middle">{{ user.email }}</td>
                <td class="align-middle">{{ user.role }}</td>
                <td class="align-middle">{{ formatDate(user.createdAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { onMounted, onUnmounted, ref } from "vue";
import HeaderBar from "../components/HeaderBar";
import { addDoc, collection, db, doc, getDocs, onSnapshot, serverTimestamp, setDoc } from "../firebase"; // Ensure firebase is configured

export default {
  name: "AppAdmin",
  components: {
    HeaderBar,
  },
  setup() {
    
    const domain = ref("");
    const domains = ref("");
    const alert = ref(null);
    const isValidDomains = ref(false);
    const domainError = ref(null);
    const companies = ref([]); 
    const users = ref([]); // Add this to hold the user data
    const activeTab = ref("companies"); // Control active tab

    const validateDomains = () => {
    const regex = /^(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?\.)+[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62}$/i;
    const domainList = domains.value.split(",").map((domain) => domain.trim());
    
    isValidDomains.value = domainList.every((domain) => regex.test(domain));
    
    if (!isValidDomains.value) {
      domainError.value = "Please enter valid domain names (comma separated)";
    } else {
      domainError.value = null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const domainList = domains.value.split(",").map((domain) => domain.trim().toLowerCase());
      
      for (const domain of domainList) {
        if (domain) {
          await setDoc(doc(db, "companies", domain), {
            companyDomain: domain,
            showRecord: false,
            createdAt: serverTimestamp(),
          });
        }
      }
      
      domains.value = ""; // Clear the input field
      alert.value = "New companies have been added to the database.";
    } catch (error) {
      console.error("Error adding companies: ", error);
    }
  };

    const closeAlert = () => {
      alert.value = false;
    };

    const formatDate = (date) => {
      if (date) {
        const unixTimestamp = date.seconds;
        return dayjs.unix(unixTimestamp).format('D MMMM YYYY');
      }
    };
    
    const fetchCompanies = async () => {
      const querySnapshot = await getDocs(collection(db, "companies"));
      companies.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users")); // Fetch from 'users' collection
      users.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    const unsubscribeCompanies = onSnapshot(collection(db, "companies"), (snapshot) => {
      companies.value = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    const unsubscribeUsers = onSnapshot(collection(db, "users"), (snapshot) => {
      users.value = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    const runNewTest = async (company) => {
      try {
         // Ensure the company object has valid fields
    if (!company || !company.companyName || !company.companyDomain) {
      alert.value = "Invalid company data provided.";
      return;
    }
        const _testingStartTime = serverTimestamp();
        // Create a new document in the foreshadowTests collection
        const testRef = await addDoc(collection(db, "foreshadowTests"), {
          companyId: company.companyName,
          _testingStartTime,
          _testingDomain: company.companyDomain, // Use the retrieved domain
          _testingCompany: company.companyName, // Use the retrieved company name
        });

       

        alert.value = `A new test has been created for company: ${company.companyName}`;
        console.log("Test created with ID: ", testRef.id);
      } catch (error) {
        console.error("Error creating test: ", error);
      }
    };

    onMounted(async () => {
      await fetchUsers(); // Fetch initial users
      await fetchCompanies(); 
    });

    onUnmounted(() => {
      unsubscribeCompanies();
      unsubscribeUsers();
    });

    return {
    domains,
    alert,
    handleSubmit,
    closeAlert,
    isValidDomains,
    domainError,
    validateDomains,
    companies,
    users,
    formatDate,
    activeTab,
    runNewTest,
  };
  },
};
</script>


<style scoped>
.container {
  margin-top: 20px;
}
</style>