// src/main.js
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue';
import { VueFire, VueFireAuth } from 'vuefire';
import App from './App.vue';
import { auth, db } from './firebase'; // Ensure this path is correct
import router from './router';


const app = createApp(App);

// Sentry.init({
//     app,
//     dsn: "https://0aa2b8b76b1fef3f718bf4a2e553c34c@o4508176904683520.ingest.us.sentry.io/4508176906190848",
//     integrations: [
//       Sentry.browserTracingIntegration({ router }),
//       Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
  

app.use(router);

app.use(VueFire, {
    firebaseApp: auth.app, // Ensure the correct firebaseApp instance is provided
    modules: [
        VueFireAuth(),
    ],
    firestore: {
        db,
    }
});

app.mount('#app');

myUndefinedFunction();
